var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"800px","persistent":"","scrollable":""},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" Nueva Actividad ")])]),_c('v-card-text',{staticClass:"mt-10"},[_c('validation-observer',{ref:"form"},[_c('v-container',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-0 pb-0"},[_c('validation-provider',{attrs:{"name":"Nombre Actividad","vid":"name","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre Actividad","required":""},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}])})],1)]),_vm._l((_vm.model.dates),function(item,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col-4 my-0 py-0"},[_c('validation-provider',{attrs:{"name":"Fecha","vid":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{ref:"date",refInFor:true,attrs:{"label":"Fecha","error":errors},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})]}}],null,true)})],1),_c('div',{staticClass:"col-3 my-0 py-0"},[_c('validation-provider',{attrs:{"name":"Hora Inicial","vid":"initialHour","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.times,"item-text":"description","item-value":"time","outlined":"","dense":"","error-messages":errors,"label":"Hora Inicial","required":""},model:{value:(item.initialHour),callback:function ($$v) {_vm.$set(item, "initialHour", $$v)},expression:"item.initialHour"}})]}}],null,true)})],1),_c('div',{staticClass:"col-3 my-0 py-0"},[_c('validation-provider',{attrs:{"name":"Hora Final","vid":"finalHour","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.times,"item-text":"description","item-value":"time","outlined":"","dense":"","error-messages":errors,"label":"Hora Final","required":""},model:{value:(item.finalHour),callback:function ($$v) {_vm.$set(item, "finalHour", $$v)},expression:"item.finalHour"}})]}}],null,true)})],1),(_vm.model.dates.length > 1)?_c('div',{staticClass:"col-2 my-0 py-0"},[_c('v-btn',{staticClass:"text--primary text-none elevation-0",attrs:{"color":"black"},on:{"click":function($event){return _vm.deleteDateRow(index)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-trash-can-outline")])],1)],1):_vm._e()])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-0 pt-0"},[_c('v-btn',{staticClass:"text-none elevation-0",attrs:{"color":"secondary","dark":""},on:{"click":_vm.addDateRow}},[_vm._v(" Agregar ")])],1)])],2)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.saveGeneralActivity}},[_vm._v(" Aceptar ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }