<template>
  <fair-layout v-if="fair" :fair-id="fair.id">
    <template v-slot:banner>
      <banner
        v-if="fair"
        :title="fair.name"
        type="banner--admin"
        :options="{padding: '30px'}">
      </banner>
    </template>
    <template v-slot:content>
      <div class="px-7">
        <div class="row">
          <div class="col-12 d-flex align-center justify-space-between">
            <h2>Configuración Programación General</h2>
            <v-btn
              color="secondary"
              class="text-none elevation-0"
              @click="openGeneralActivityModal()"
              dark>
              Nueva Actividad
            </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <data-table ref="generalActivities" :url="url" :params="params" :headers="headers" :mobile="true" :withoutPagination="true">
              <template v-slot:item.arrows="{ item }">
                <v-tooltip bottom >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-if="!item.last"
                      large
                      color="black"
                      class="ml-5"
                      center
                      v-bind="attrs"
                      v-on="on"
                      @click="onChangeOrderActivity(item, 0)"
                    >
                      fa fa-angle-down
                    </v-icon>
                    <v-icon large class="ml-10 pl-1" v-else></v-icon>
                  </template>
                  <span>Subir Posición</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-if="!item.first"
                      large
                      color="black"
                      class="ml-5"
                      center
                      v-bind="attrs"
                      v-on="on"
                      @click="onChangeOrderActivity(item, 1)"
                    >
                      fa fa-angle-up
                    </v-icon>
                    <v-icon large class="ml-5" v-else></v-icon>
                  </template>
                  <span>Bajar Posición</span>
                </v-tooltip>
              </template>
              <template v-slot:item.isActive="{ item }">
                <v-switch color="accent" v-model="item.isActive" dense hide-details class="ma-0" @click="onChangeStatusActivity(item)"></v-switch>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      class="ma-2 elevation-0"
                      v-bind="attrs"
                      v-on="on"
                      @click="openGeneralActivityModal(item)"
                      small
                      color="primary"
                    >
                      <v-icon
                        center
                        dark
                        small
                      >
                        fa fa-edit
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar Actividad</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      class="ma-2 elevation-0"
                      v-bind="attrs"
                      v-on="on"
                      @click="onDeleteActivity(item)"
                      small
                      color="primary"
                    >
                      <v-icon
                        center
                        dark
                        small
                      >
                        fa fa-trash
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar Actividad</span>
                </v-tooltip>
              </template>
            </data-table>
          </div>
        </div>
        <general-activity-modal ref="generalActivityModal" :fairId="fair.id" @onClose="onClose"/>
        <confirm-dialog
          :title="'Confirmación'"
          :description="`¿Deseas ${(Boolean(selectedActivity.isActive) ? 'activar' : 'desactivar')} la actividad?`"
          @onClose="closeChangeStatusGeneralActivityModal()"
          @onConfirm="changeStatusGeneralActivity()"
          :is-active="isConfirmChangeStatusActivity"
        />
        <confirm-dialog
          :title="'Confirmación'"
          :description="`¿Deseas eliminar la actividad ${selectedActivity.name}?`"
          @onClose="closeDeleteActivityModal()"
          @onConfirm="deleteGeneralActivity()"
          :is-active="isConfirmDeleteActivity"
        />
      </div>
    </template>
  </fair-layout>
</template>
<script>
import FairLayout from '@/layouts/FairLayout'
import DataTable from '@/components/data-table/DataTable'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import { FAIRS_URL } from '@/constants/ServicesConstants'
import { GENERAL_ACTIVITIES_URL } from '@/constants/ServicesMobileConstants'
import headers from './data/Headers'
import sessionMixin from '@/mixins/sessionMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import GeneralActivityModal from './components/GeneralActivityModal'
import Banner from '@/components/banner/Banner'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog'

export default {
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isSuperAdmin(user.roleId)) {
      next()
    } else {
      next({ path: '/error-403' })
    }
  },
  components: {
    FairLayout,
    Banner,
    ConfirmDialog,
    DataTable,
    GeneralActivityModal
  },
  data () {
    return {
      headers,
      url: GENERAL_ACTIVITIES_URL,
      params: {
        fairId: this.$route.params.fairId
      },
      fair: null,
      selectedActivity: {},
      isConfirmChangeStatusActivity: false,
      isConfirmDeleteActivity: false
    }
  },
  methods: {
    isActive (isActive) {
      return Boolean(isActive)
    },
    onClose () {
      this.$refs.generalActivities.getDataFromApi()
    },
    openGeneralActivityModal (item) {
      this.$refs.generalActivityModal.open(item)
    },
    onChangeStatusActivity (item) {
      this.selectedActivity = item
      this.isConfirmChangeStatusActivity = true
    },
    onChangeOrderActivity (item, toMove) {
      this.selectedActivity = item
      this.selectedActivity.toMove = toMove
      this.changeOrderGeneralActivity()
    },
    onDeleteActivity (item) {
      this.selectedActivity = item
      this.isConfirmDeleteActivity = true
    },
    changeStatusGeneralActivity () {
      this.isConfirmChangeStatusActivity = false
      this.putMobile(GENERAL_ACTIVITIES_URL + '/change-status/' + this.selectedActivity.id, this.selectedActivity)
        .then((res) => {
          this.showSuccess(res.data.success)
          this.$refs.generalActivities.getDataFromApi()
        })
    },
    deleteGeneralActivity () {
      this.isConfirmDeleteActivity = false
      this.deleteMobile(GENERAL_ACTIVITIES_URL, this.selectedActivity.id)
        .then((res) => {
          this.showSuccess(res.data.success)
          this.$refs.generalActivities.getDataFromApi()
        })
    },
    changeOrderGeneralActivity () {
      this.getMobile(GENERAL_ACTIVITIES_URL + '/change-order/' + this.selectedActivity.id + '/' + this.selectedActivity.toMove)
        .then((res) => {
          this.showSuccess(res.data.success)
          this.$refs.generalActivities.getDataFromApi()
        })
    },
    closeChangeStatusGeneralActivityModal () {
      this.isConfirmChangeStatusActivity = false
      this.selectedActivity.isActive = !this.selectedActivity.isActive
    },
    closeDeleteActivityModal () {
      this.isConfirmDeleteActivity = false
    },
    async getFair () {
      const response = await this.get(FAIRS_URL + '/' + this.$route.params.fairId)
      this.fair = response.data
    }
  },
  async created () {
    try {
      this.showLoader()
      await this.getFair()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  mixins: [
    crudServiceMixin,
    crudServiceMobileMixin,
    loaderMixin,
    notificationMixin
  ],
  name: 'GeneralProgramming'
}
</script>
